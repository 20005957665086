import React, { Component } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBRow,
    MDBCol,
    MDBIcon,
    toast,
    ToastContainer,
    MDBCard,
    MDBCardBody,
} from "mdbreact";
import axios from "../../../axios";
import "react-multi-carousel/lib/styles.css";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import Data from "../../../components/SubscriberMgmt/CumulativeBandwidth/Datachart/datachart";
import AddProfile from "../../../components/SubscriberMgmt/AddProfile/AddProfile";
import DeviceDetails from "../../../components/SubscriberMgmt/DeviceInformation/DeviceDetails";
import { withTranslation } from "react-i18next";
import AllWifiKeysTable from "../../../components/SubscriberMgmt/KeyInformation/Datatables/AllWifiKeysMoreDetailsTable";
class KeyManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleEdit: "disabled",
            showPassword: true,
            modalEditNickname: false,
            modalEditPassword: false,
            venueName: this.props.venueName,
            selectedKeyName:
                this.props.keyNameListArrayAll[0][
                    this.props.selectedNetworkIndex
                ]["KeyTag"],
            password: this.props.password,
            tag: this.props.keyNameListArrayAll[0][
                this.props.selectedNetworkIndex
            ]["psk"].split(".")[1],
            // addedOn: new Intl.DateTimeFormat("en-US", {
            // 	year: "numeric",
            // 	month: "numeric",
            // 	day: "numeric",
            // }).format(new Date(this.props.sortedKeys[this.props.selectedNetworkIndex]["dateAddedUTC.ISO8601"])),
            // recentUse:
            // 	this.props.sortedKeys[this.props.selectedNetworkIndex]["mostRecentUseUTC.ISO8601"] !== undefined
            // 		? new Intl.DateTimeFormat("en-US", {
            // 				year: "numeric",
            // 				month: "numeric",
            // 				day: "numeric",
            // 		  }).format(new Date(this.props.sortedKeys[this.props.selectedNetworkIndex]["mostRecentUseUTC.ISO8601"]))
            // 		: "",
            selectedName: null,
            category: [],
            name: [],
            data: [],
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.password !== this.props.password) {
            this.setState({ password: this.props.password });
        }
    }
    toggleAddProfile = () => {
        this.setState({
            modalAddProfile: !this.state.modalAddProfile,
        });
    };
    toggleEditNickname = () => {
        this.setState({
            selectedKeyName: this.props.selectedKeyName,
            modalEditNickname: !this.state.modalEditNickname,
        });
    };
    toggleEditPassword = () => {
        this.setState({
            modalEditPassword: !this.state.modalEditPassword,
        });
    };
    togglePassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    toggleEdit = () => {
        this.setState({ toggleEdit: !this.state.toggleEdit });
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    submitEditNickname = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            KeyID: this.props.keyID,
            KeyTag: this.state.selectedKeyName,
        };
        axios
            .post("/subscriberconsole/updateKeyTag", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    modalEditNickname: !this.state.modalEditNickname,
                });
                toast.success("Nickname Updated", {
                    closeButton: false,
                });
                this.props.updateNameKeyProfile(
                    this.state.selectedKeyName,
                    this.props.keyID,
                    this.props.keyInfo,
                    this.state.password.split(".")[0],
                    this.state.tag
                );
            })
            .catch((error) => {
                this.setState({ error: true });
                toast.error("Nickname Not Updated", {
                    closeButton: false,
                });
            });
    };
    submitEditPassword = (currentKeyID) => {
        if (this.state.password !== null) {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                CurrentKeyID: currentKeyID,
                UserPartForNewKey: this.state.newpsk,
            };
            axios
                .post("/subscriberconsole/resetKey", body, {
                    headers: {},
                })
                .then((response) => {
                    this.setState({
                        modalEditPassword: !this.state.modalEditPassword,
                    });
                    toast.success("Password Updated", {
                        closeButton: false,
                    });
                    this.props.updateNameKeyProfile(
                        this.props.selectedKeyName,
                        this.props.keyID,
                        this.props.keyInfo,
                        this.state.newpsk + "." + this.state.tag,
                        this.state.tag
                    );
                })
                .catch((error) => {
                    this.setState({ error: true });
                    toast.error("Password Not Updated", {
                        closeButton: false,
                    });
                });
        } else {
            toast.error(
                "We encountered an error. Please reload the page and try again.",
                {
                    closeButton: false,
                }
            );
        }
    };
    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }
    setPassword = (password, tag) => {
        let scrubbedPassword = password.replace(/\./g, "");
        // console.log("🚀 ~ file: KeyInformation.js:245 ~ KeyInformation ~ newpassword:", scrubbedPassword)
        this.setState({ newpsk: scrubbedPassword });
        this.setState({ password: scrubbedPassword + "." + tag });
        this.setState({ tag: tag });
    };
    copyPassword = () => {
        var copyText = document.getElementById("passwordHidden");
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        this.setState({ copyText: "Copied" });
        textArea.remove();
        toast.success("Password copied", {
            closeButton: false,
        });
    };
    renderDeviceIcon(param) {
        switch (param) {
            case "NetworkDevice":
                return "mobile-alt";
            case "Laptop":
                return "laptop";
            case "Unknown":
                return "question";
            default:
                return "mobile-alt";
        }
    }
    toggleDeviceDetails = (device) => {
        this.setState({
            modalDeviceDetails: !this.state.modalDeviceDetails,
            deviceDetails: device,
        });
    };
    toggleDeviceDetailsClose = () => {
        this.setState({
            modalDeviceDetails: !this.state.modalDeviceDetails,
        });
    };
    validateForm() {
        if (this.state.password !== null) {
            return this.state.password.split(".")[0].length >= 1;
        }
    }
    render() {
        const { t } = this.props;
        let firstKeyID;
        let password;
        if (this.state.password !== null) {
            password = this.state.password;
        } else {
            password = this.props.firstPSK;
        }
        let keyNameListArray = this.props.keyNameListArray;
        if (
            this.props.venueName[0] !== undefined &&
            this.props.sortedKeys !== undefined
        ) {
            firstKeyID = Object.keys(this.props.sortedKeys[0])[2];
        }
        return (
            <>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={10000}
                />
                <MDBModal
                    isOpen={this.state.modalAddProfile}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                >
                    <AddProfile
                        toggleAddProfile={this.toggleAddProfile}
                        keyInfo={this.props.keyInfo}
                        ssidName={this.props.ssidName}
                        password={
                            this.props.password === null
                                ? this.props.firstPSK
                                : this.props.password
                        }
                        keyID={this.props.keyID}
                        updateNameKey={this.props.updateNameKey}
                        updateNameNetwork={this.props.updateNameNetwork}
                        SessionList={this.props.SessionList}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalDeviceDetails}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                >
                    <DeviceDetails
                        toggleDeviceDetails={this.toggleDeviceDetailsClose}
                        deviceDetails={this.state.deviceDetails}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalEditNickname}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                >
                    <MDBModalHeader toggle={this.toggleEditNickname}>
                        <h4>Edit Nickname</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Nickname
                                </label>
                                <input
                                    id="nickname"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="selectedKeyName"
                                    value={
                                        this.state.selectedKeyName === null
                                            ? this.props.firstKeyName
                                            : this.state.selectedKeyName
                                    }
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn
                                color="danger"
                                onClick={this.submitEditNickname}
                            >
                                Submit
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleEditNickname}
                            >
                                Cancel
                            </MDBBtn>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalEditPassword}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                >
                    <MDBModalHeader toggle={this.toggleEditPassword}>
                        <h4>Wi-Fi Password Reset</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol col="6">
                                <p>WARNING</p>
                                <p>
                                    Devices connected to this network will need
                                    to be reconfigured with the new password.
                                    Your old password will remain active for two
                                    hours. Upon expiration, devices using that
                                    password will lose the Wi-Fi connection.
                                </p>
                                <p className="updateNote">Please note, it will take up to 5 minutes for the new Wi-Fi password to activate and take effect.</p>
                                <div className="form-group row">
                                    <div className="col-sm-2">
                                        <p>Profile</p>
                                    </div>
                                    <div className="col-sm-6">
                                        {this.props.selectedKeyName}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-2">
                                        <p>Network</p>
                                    </div>
                                    <div className="col-sm-6">
                                        {this.props.ssidName}
                                    </div>
                                </div>
                                <p className="wifiDisclaimer">
                                    Enter a new Personal Token below. Your Wi-Fi
                                    password is the combination of your personal
                                    token and your unique ID. For security
                                    purposes, the unique ID cannot be modified.
                                </p>
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text passwordLabel"
                                >
                                    Password
                                </label>
                                <input
                                    id="editpassword"
                                    className="form-control"
                                    onChange={(e) =>
                                        this.setPassword(
                                            e.target.value,
                                            password.split(".")[1] === undefined
                                                ? this.props.token
                                                : password.split(".")[1]
                                        )
                                    }
                                    name="password"
                                    value={
                                        this.state.password === null
                                            ? this.props.firstPSK.split(".")[0]
                                            : this.state.password.split(".")[0]
                                    }
                                />
                                <span className="password_subscriber">
                                    .
                                    {this.state.password.split(".")[1] ===
                                    undefined
                                        ? this.props.token
                                        : this.state.password.split(".")[1]}
                                </span>
                                <div className="invalid-feedback">
                                    Please provide a valid password.
                                </div>
                                <br />
                                <br />
                                <p>New Wi-Fi Password</p>
                                <input
                                    id="editpassword2"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="password"
                                    value={
                                        this.state.tag !== undefined
                                            ? this.state.password.split(
                                                  "."
                                              )[0] +
                                              "." +
                                              this.state.tag
                                            : this.state.password.split(
                                                  "."
                                              )[1] === undefined
                                            ? this.state.password +
                                              "." +
                                              this.props.token
                                            : this.state.password
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn
                                color="danger"
                                disabled={!this.validateForm()}
                                onClick={(e) =>
                                    this.submitEditPassword(
                                        this.state.selectedKeyID !== undefined
                                            ? this.state.selectedKeyID
                                            : firstKeyID
                                    )
                                }
                            >
                                Submit
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleEditPassword}
                            >
                                Cancel
                            </MDBBtn>
                        </div>
                    </MDBModalBody>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleManageKey}>
                    <h4>Profile Details</h4>
                </MDBModalHeader>
                <MDBModalBody style={{ margin: "0px 20px 0px 10px" }}>
                    <MDBRow>
                        <MDBCol sm="6">
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    <p>
                                        <strong>
                                            {t("profiledetails.profile")}
                                        </strong>
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret>
                                            <span
                                                className="mr-2"
                                                onClick={this.click}
                                            >
                                                {this.props.selectedKeyName ===
                                                null
                                                    ? this.props.firstKeyName
                                                    : this.props
                                                          .selectedKeyName}
                                            </span>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {this.props.keyNameListArray.map(
                                                (value, index) => {
                                                    return (
                                                        <MDBDropdownItem
                                                            onClick={(e) =>
                                                                this.props.updateNameKey(
                                                                    keyNameListArray[
                                                                        index
                                                                    ][1],
                                                                    keyNameListArray[
                                                                        index
                                                                    ][0]
                                                                )
                                                            }
                                                        >
                                                            {
                                                                keyNameListArray[
                                                                    index
                                                                ][1]
                                                            }
                                                        </MDBDropdownItem>
                                                    );
                                                }
                                            )}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                                <div className="col-sm-2 formControls">
                                    {this.props.iswpa2 === false ? (
                                        <MDBIcon
                                            icon="edit"
                                            onClick={this.toggleEditNickname}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    <p>
                                        <strong>
                                            {t("profiledetails.network")}
                                        </strong>
                                    </p>
                                </div>
                                <MDBDropdown className="col-sm-6">
                                    <MDBDropdownToggle nav caret>
                                        <span className="mr-2">
                                            {this.props.ssidName}
                                        </span>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {this.props.SessionList.map(
                                            (value, index) => {
                                                return (
                                                    <MDBDropdownItem
                                                        onClick={(e) =>
                                                            this.props.updateNameNetwork(
                                                                this.props
                                                                    .SessionList[
                                                                    index
                                                                ][0]["ssid"],
                                                                index
                                                            )
                                                        }
                                                    >
                                                        {
                                                            this.props
                                                                .SessionList[
                                                                index
                                                            ][0]["ssid"]
                                                        }
                                                    </MDBDropdownItem>
                                                );
                                            }
                                        )}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text col-form-label"
                                        >
                                            <strong>
                                                {t("profiledetails.password")}
                                            </strong>{" "}
                                            {this.state.showPassword !==
                                            true ? (
                                                <MDBIcon
                                                    icon="eye"
                                                    onClick={
                                                        this.togglePassword
                                                    }
                                                />
                                            ) : (
                                                <MDBIcon
                                                    icon="eye-slash"
                                                    onClick={
                                                        this.togglePassword
                                                    }
                                                />
                                            )}
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <p
                                            className="inputBox"
                                            id="password"
                                            style={{ marginBottom: 0 }}
                                        >
                                            {this.state.showPassword !== false
                                                ? password === null
                                                    ? this.props.firstPSK
                                                    : password
                                                : "************"}
                                        </p>
                                        <span id="passwordHidden">
                                            {password === null
                                                ? this.props.firstPSK.split(
                                                      "."
                                                  )[1] === undefined
                                                    ? this.props.firstPSK
                                                    : this.props.firstPSK
                                                : password}
                                        </span>
                                    </div>
                                    <div className="col-sm-2 formControls">
                                        {this.props.iswpa2 === false ? (
                                            <MDBIcon
                                                icon="edit"
                                                onClick={
                                                    this.toggleEditPassword
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <MDBIcon
                                            icon="copy"
                                            onClick={this.copyPassword}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol sm="6">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>
                                            <strong>
                                                {t("profiledetails.activated")}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="col-sm-8">
                                     <p>
                                        {this.state.addedOn}
                                     </p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>
                                            <strong>
                                                {t("profiledetails.recentuse")}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="col-sm-8">
                                     <p>
                                        {this.state.recentUse}
                                     </p>
                                    </div>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol sm="12">
                            <p>
                                <strong>Subscriptions</strong>
                            </p>
                            <AllWifiKeysTable
                                venueUnitPolicyInfo={
                                    this.props.venueUnitPolicyInfo
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <br />
                    {
                     !this.props.isUbiquiti ? (
                    
                    <MDBRow>
                        <MDBCol sm="6">
                            <p>
                                <strong>{t("profiledetails.devices")}</strong>
                            </p>
                            <div>
                                {Object.keys(this.props.devices).length > 0 ? (
                                    <div
                                        className="devices"
                                        style={{
                                            border: "1px solid #CCC",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            minHeight: "232px",
                                        }}
                                    >
                                        {Object.keys(this.props.devices)
                                            .slice(0, 3)
                                            .map((oneKey, i) => {
                                                return (
                                                    <div
                                                        className="deviceCard"
                                                        onClick={(e) =>
                                                            this.toggleDeviceDetails(
                                                                this.props
                                                                    .devices[
                                                                    oneKey
                                                                ]
                                                            )
                                                        }
                                                    >
                                                        <MDBIcon
                                                            icon={this.renderDeviceIcon(
                                                                this.props
                                                                    .devices[
                                                                    oneKey
                                                                ]["DeviceType"]
                                                            )}
                                                        />
                                                        <div
                                                            className={
                                                                this.props
                                                                    .devices[
                                                                    oneKey
                                                                ][
                                                                    "currentlyOnline"
                                                                ] === true
                                                                    ? "deviceStatusConnected"
                                                                    : "deviceStatusDisconnected"
                                                            }
                                                        ></div>
                                                        <p>
                                                            {
                                                                this.props
                                                                    .devices[
                                                                    oneKey
                                                                ][
                                                                    "UserDefinedHostName"
                                                                ]
                                                            }
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : (
                                    <MDBCard
                                        style={{
                                            border: "1px solid #CCC",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            minHeight: "232px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <MDBCardBody
                                            className="messageCardBody1"
                                            style={{ flex: "unset" }}
                                        >
                                            <h4 style={{ color: "#BBB" }}>
                                                No devices to display yet
                                            </h4>
                                        </MDBCardBody>
                                    </MDBCard>
                                )}
                            </div>
                        </MDBCol>
                        <MDBCol sm="6">
                            <p>
                                <strong>{t("profiledetails.bandwidth")}</strong>
                            </p>
                            <div
                                style={{
                                    border: "1px solid #CCC",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                <Data
                                    selectedKeyID={this.props.keyIDForBandwith}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                     ) : ""
                  }
                </MDBModalBody>
                <MDBModalFooter>
                    <div className="text-right">
                        <MDBBtn
                            color="danger"
                            onClick={this.props.toggleManageKey}
                        >
                            OK
                        </MDBBtn>
                    </div>
                </MDBModalFooter>
            </>
        );
    }
}
export default withTranslation()(KeyManage);
