import axios from "axios";
const host = window.location.host;
let instance;
switch (host) {
 case "demo3.roamingiq.com": {
  instance = axios.create({
      baseURL: "https://api.wifikey.io/preview",
  });
  break;
}
    case "www.mydishfiber.com": {
        instance = axios.create({
            baseURL: "https://api.mydishfiber.com/v1",
        });
        break;
    }
    case "xperity.wifikey.link": {
        instance = axios.create({
            baseURL: "https://apixperity.us-east-1.wifikey.link/xperity",
        });
        break;
    }
    case "localhost:3000": {
        instance = axios.create({
            baseURL: "https://api.wifikey.link/v1qa",
        });
        break;
    }
    case "localhost:3001": {
      instance = axios.create({
          baseURL: "https://api.roamingiqtest.com/v1",
      });
      break;
  }
    default: {
        instance = axios.create({
            baseURL: "https://api.wifikey.io/v1",
        });
        break;
    }
}
sessionStorage.setItem("host", host);
// baseURL: 'https://api.roamingiqtest.com/v1qa'
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;
        //console.log(originalRequest);
        const refreshToken = sessionStorage.getItem("AccessToken");
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest && refreshToken) {
            originalRequest._retry = true;
            //console.log("Refreshing");
            instance
                .post("/subscriberconsole/refreshAccessToken", {
                    AccessToken: sessionStorage.getItem("AccessToken"),
                    RefreshToken: sessionStorage.getItem("RefreshToken"),
                })
                //.then((res) => res.json())
                .then((tokenRefreshResponse) => {
                    //console.log("Refreshing");
                    //console.log(tokenRefreshResponse);
                    sessionStorage.setItem("AccessToken", tokenRefreshResponse.data.AuthenticationResult.AuthenticationResult.AccessToken);
                    originalRequest.headers["Authorization"] = tokenRefreshResponse.data.AuthenticationResult.AuthenticationResult.AccessToken;
                    return Promise.resolve();
                });
        }
        return Promise.reject(error);
    }
);
export default instance;